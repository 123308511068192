import React from 'react'
import Helmet from 'react-helmet'

import config from '../utils/siteConfig'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Post from '../components/Post'

const NotFoundPage = () => {
  const post = {
    title: '404 - Page introuvable',
    body: "Il semblerait que la page que vous recherchez n'existe plus...",
  }

  return (
    <Layout>
      <Helmet>
        <title>{`${post.title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={post} excerpt={post.body} postSEO />
      <Post {...post} body={post.body} />
    </Layout>
  )
}

export default NotFoundPage
